import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import ContainerWrapper from "../components/Container"
import marked from 'marked'
import { Helmet } from "react-helmet"

const NewsPageTemplate = ({data}) => {
  const item = data.sanityPost;
  return(
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{item.title} - IMSSDARM Asian Division</title>
        <link rel="canonical" href={item.slug.current} />
        <meta name="description" content={item.body}></meta>
      </Helmet>
      <PageHeader>
        <h1 className="text-center">{item.title}</h1>
        <p className="text-center">{item._updatedAt}</p>
      </PageHeader>
      <ContainerWrapper>
        <div className="col-md-8 m-auto">
          <img src={item.mainImage.asset.url} alt={item.title} className="img-fluid m-auto d-block" />
          <div className="my-5" dangerouslySetInnerHTML={{ __html: marked(item.body) }} />
          <Link to="/">Go home</Link>
        </div>
      </ContainerWrapper>
    </Layout>
  )
}


export default NewsPageTemplate

export const pageQuery = graphql`
  query NewsPageTemplate($id: String) {
    sanityPost(id: { eq: $id }) {
      _id
      title
      body
      mainImage {
        asset {
          url
        }
      }
      slug {
        current
      }
      _updatedAt(formatString: "MMM DD, yyyy")
    }
  }
`
